@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600&display=swap");

@font-face {
  font-family: "Sequel Sans";
  src: url("./assets/SequelSans/OTF/SequelSansRomanDisp.otf") format("opentype"),
    url("./assets/SequelSans/TTF/SequelSansRomanDisp.ttf") format("truetype");
  font-weight: 410 1000;
  font-display: swap;
}

@font-face {
  font-family: "Sequel Sans";
  src: url("./assets/SequelSans/OTF/SequelSansRomanHead.otf") format("opentype"),
    url("./assets/SequelSans/TTF/SequelSansRomanHead.ttf") format("truetype");
  font-weight: 0 400;
  font-display: swap;
}

:root {
  font-family: "Inter", "system-ui";
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", "system-ui";
  }
}

.monaco-editor .overflow-guard {
  position: relative !important;
}

a,
a:hover,
a:active {
  text-decoration: inherit; /* no underline */
  color: inherit;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(73%) sepia(10%) saturate(319%) hue-rotate(188deg) brightness(89%) contrast(83%);
  pointer-events: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
  margin: 0;
  background-color: rgb(29, 29, 29);
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left {
  display: none;
}

button {
  border: 1px solid #cccccc;
}

input {
  border: 1px solid #cccccc;
}

.link {
  color: blue;
  text-decoration: underline;
}

.logo {
  margin: auto;
}

/* plot styles */

.rv-xy-plot {
  background-color: #fff;
  color: #ffffff;
}

.rv-xy-plot__grid-lines__line {
  stroke: rgba(30, 53, 77, 0.1);
}

.rv-xy-plot__axis__tick__text {
  fill: rgba(30, 53, 77, 0.7);
  font-size: 9px;
}

.rv-xy-plot__axis__title text {
  fill: rgba(30, 53, 77, 0.7);
  font-size: 12px;
}

.ignore-css {
  all: unset !important;
}

.ReactCollapse--collapse {
  transition: height 100ms;
}

.me {
  /* height: 85% !important; */
  padding: 0 !important;
}

/* overwrite react-draggable-tabs styles */
.react-tabs-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
  border: none !important;
  height: 100% !important;
}

.react-tabs-tab-close {
  display: none !important;
}

.react-tabs-tab {
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
  border: none !important;
  height: 100% !important;
  max-width: min-content !important;
  width: min-content !important;
}

.react-tabs-active {
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
  border: none !important;
}

.react-tabs-tab-content {
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
  border: none !important;
  height: 100% !important;
  max-width: min-content !important;
  width: min-content !important;
}

.react-tabs-child {
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
  border: none !important;
}

/* Custom styles for crisp chat */

/*.crisp-client > #crisp-chatbox.cc-52lo > .cc-kegp > a.cc-1oun > .cc-1qth,*/
/*.crisp-client > #crisp-chatbox.cc-52lo[data-full-view=true] > .cc-kegp > a.cc-1oun > .cc-1qth {*/
/*  width: 40px !important;*/
/*  height: 40px !important;*/
/*}*/

/*.crisp-client > #crisp-chatbox.cc-52lo[data-last-operator-face=false] > .cc-kegp > .cc-1oun > .cc-1qth > .cc-lgzk > .cc-3ycs,*/
/*.crisp-client > #crisp-chatbox.cc-52lo[data-last-operator-face=false] > .cc-kegp > .cc-1oun > .cc-1qth > .cc-lgzk > .cc-3ycs[data-is-ongoing=false] {*/
/*  width: 24px !important;*/
/*  height: 21px !important;*/
/*  top: 11px !important;*/
/*  left: 8px !important;*/
/*}*/
